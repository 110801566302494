export const manifestsCatalog = [
    { manifestId: 'https://files.tetras-libre.fr/manifests/re_walden_cut.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/jf_peyret_re_walden.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/test_markeas_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/installation_fresnoy_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/sceno_avignon_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/walden_nouvel_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/walden_nouvel2_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/score_manifest.json' },
    { manifestId: 'https://files.tetras-libre.fr/manifests/program_manifest.json' },
];
